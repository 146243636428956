<template>

  <div>

    <!-- Filters -->
    <filters
      :date-from-filter.sync="dateFromFilter"
      :date-to-filter.sync="dateToFilter"
      :status-filter.sync="statusFilter"
      :type-filter.sync="typeFilter"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('admin.table.settings.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('admin.table.settings.entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="warning"
                @click="exportRun"
              >
                <span class="text-nowrap">Export</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('admin.table.settings.empty_text')"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(id)="data">
          {{ `#${data.item.order.id}` }}
        </template>
        <template #cell(name)="data">
          {{ data.item.order.user ? data.item.order.user.name : '' }}
        </template>
        <template #cell(email)="data">
          {{ data.item.order.user ? data.item.order.user.email : '' }}
        </template>
        <template #cell(phone)="data">
          {{ data.item.order.user ? data.item.order.user.phone : '' }}
        </template>

        <template #cell(city)="data">
          {{ data.item.order.city ? data.item.order.city.title : '' }}
        </template>
        <template #cell(payment)="data">
          {{ data.item.order.paymentMethod ? data.item.order.paymentMethod.title : '' }}
        </template>
        <template #cell(delivery)="data">
          {{ data.item.order.deliveryMethod ? data.item.order.deliveryMethod.title : '' }}
        </template>
        <template #cell(address)="data">
          {{ data.item.order.address }}
        </template>
        <template #cell(created_at)="data">
          {{ data.item.order.created_at }}
        </template>

        <template #cell(title)="data">
          {{ data.item.product.title }}
        </template>
        <template #cell(sku)="data">
          {{ data.item.product.sku }}
        </template>
        <template #cell(quantity)="data">
          {{ data.item.quantity }}
        </template>
        <template #cell(price)="data">
          <span v-html="$options.filters.money(data.item.price)" />
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('admin.table.settings.showing') }} {{ dataMeta.from }} {{ $t('admin.table.settings.to') }} {{ dataMeta.to }} {{ $t('admin.table.settings.of') }} {{ dataMeta.of }} {{ $t('admin.table.settings.entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import router from '@/router'
import Filters from './Filters.vue'
import useList from './useList'
import storeModule from './storeModule'

export default {
  components: {
    Filters,
  },
  mixins: [GlobalMixin],
  data() {
    return {
      tableColumns: [
        { key: 'id', label: 'ID', sortable: false },
        { key: 'name', label: this.$t('admin.table.fields.name'), sortable: false },
        { key: 'email', label: this.$t('admin.table.fields.email'), sortable: false },
        { key: 'phone', label: this.$t('admin.table.fields.phone'), sortable: false },
        { key: 'city', label: this.$t('admin.table.fields.city'), sortable: false },
        { key: 'payment', label: this.$t('admin.table.fields.payment'), sortable: false },
        { key: 'delivery', label: this.$t('admin.table.fields.delivery'), sortable: false },
        { key: 'address', label: this.$t('admin.table.fields.address'), sortable: false },
        { key: 'created_at', label: this.$t('admin.table.fields.created_at'), sortable: false },
        { key: 'title', label: this.$t('admin.table.fields.title'), sortable: false },
        { key: 'sku', label: this.$t('admin.table.fields.sku'), sortable: false },
        { key: 'quantity', label: this.$t('admin.table.fields.quantity'), sortable: false },
        { key: 'price', label: this.$t('admin.table.fields.price'), sortable: false },
      ],
    }
  },
  methods: {
    exportRun() {
      this.confirm(() => {
        this.$http.get(`/api/reports/${router.currentRoute.params.type}/export`, {
          responseType: 'blob',
          params: {
            date_from: this.dateFromFilter,
            date_to: this.dateToFilter,
            status: this.statusFilter,
            type: this.typeFilter,
          },
        })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'report_orders.xlsx')
            document.body.appendChild(link)
            link.click()
            link.remove()
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }, {
        text: 'Export to file?',
      })
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'reports'
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      fetchData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Extra Filters
      statusFilter,
      typeFilter,
      dateFromFilter,
      dateToFilter,
    } = useList()

    return {
      fetchData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Extra Filters
      statusFilter,
      typeFilter,
      dateFromFilter,
      dateToFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
