<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('admin.filters.title') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="6"
          md="3"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            :label="$t('form.status.label')"
            :label-for="'status'"
          >
            <v-select
              id="status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="statusFilter"
              :options="statusOptions"
              class="w-100"
              :reduce="val => val.value"
              @input="(val) => $emit('update:statusFilter', val)"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            :label="$t('form.type.label')"
            :label-for="'type'"
          >
            <v-select
              id="type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="typeFilter"
              :options="typeOptions"
              class="w-100"
              :reduce="val => val.value"
              @input="(val) => $emit('update:typeFilter', val)"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            :label="$t('form.date_from.label')"
            :label-for="'date_from'"
          >
            <b-form-datepicker
              :id="'date_from'"
              :value="dateFromFilter"
              type="date"
              @input="(val) => $emit('update:dateFromFilter', val)"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            :label="$t('form.date_to.label')"
            :label-for="'date_to'"
          >
            <b-form-datepicker
              :id="'date_to'"
              :value="dateToFilter"
              type="date"
              @input="(val) => $emit('update:dateToFilter', val)"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormGroup, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BFormDatepicker,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  props: {
    statusFilter: {
      type: [String, null],
      default: null,
    },
    typeFilter: {
      type: [String, null],
      default: null,
    },
    dateFromFilter: {
      type: [String, null],
      default: null,
    },
    dateToFilter: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      statusOptions: [
        { label: this.$t('orders.statuses.new'), value: 'new' },
        { label: this.$t('orders.statuses.paid'), value: 'paid' },
        { label: this.$t('orders.statuses.notpaid'), value: 'notpaid' },
        { label: this.$t('orders.statuses.inwork'), value: 'inwork' },
        { label: this.$t('orders.statuses.completed'), value: 'completed' },
        { label: this.$t('orders.statuses.cancel'), value: 'cancel' },
        { label: this.$t('orders.statuses.delivering'), value: 'delivering' },
      ],
      typeOptions: [
        { label: this.$t('general.orderTypes.full'), value: 'full' },
        { label: this.$t('general.orderTypes.fast'), value: 'fast' },
      ],
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
